<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
            @selection-change="handleSelectionChange"
        >
            <el-table-column
                type="selection"
                width="55"
                v-if="
                    searchData.param &&
                    searchData.param.sourceCategory == 1 &&
                    btnP.distDep &&
                    isSelection
                "
                :selectable="handleDisable"
            >
            </el-table-column>
            <el-table-column prop="updateTime" label="创建时间" width="93">
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        @click="onDetails(scope.row)"
                        >{{
                            $tableDataHandle.createTime({
                                createTime: scope.row.createTime,
                            })
                        }}</el-button
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="联系方式" width="180">
                <template slot="header">
                    <div>&nbsp;&nbsp;联系方式</div>
                </template>
                <template slot-scope="scope">
                    <div
                        v-if="
                            (scope.row.lineContactBOList &&
                                scope.row.lineContactBOList.length > 0) ||
                            (scope.row.customerMainContactBOList &&
                                scope.row.customerMainContactBOList.length > 0)
                        "
                    >
                        <el-tooltip
                            v-if="
                                scope.row.lineContactBOList &&
                                scope.row.lineContactBOList.length > 0
                            "
                            class="item"
                            effect="dark"
                            placement="bottom"
                        >
                            <div slot="content" class="way_box">
                                <p
                                    v-for="item in scope.row.lineContactBOList"
                                    :key="item.id"
                                >
                                    <span class="way_box_fs">
                                        <em>
                                            {{
                                                $tableDataHandle.contactWay(
                                                    item
                                                )
                                            }}</em
                                        >
                                        {{ item.contactContent }}
                                    </span>
                                    <span
                                        class="copy2"
                                        v-if="item.number > 1"
                                        @click="telSearch(item)"
                                    >
                                        <i
                                            class="iconfont icon-copy-rect-full"
                                        ></i>
                                        {{ item.number }}
                                    </span>
                                </p>
                            </div>
                            <div style="position: relative">
                                <span>
                                    <i
                                        :class="
                                            $tableDataHandle.contactWayColor(
                                                returnWay(scope.row)
                                            ).icon
                                        "
                                        :style="{
                                            color: $tableDataHandle.contactWayColor(
                                                returnWay(scope.row)
                                            ).color,
                                        }"
                                    ></i
                                    >{{ returnWay(scope.row).contactContent }}
                                </span>
                                <span
                                    class="copy"
                                    v-if="returnWay(scope.row).number > 1"
                                    @click="telSearch(returnWay(scope.row))"
                                >
                                    <i class="iconfont icon-copy-rect-full"></i>
                                    {{ returnWay(scope.row).number }}
                                </span>
                            </div>
                        </el-tooltip>
                        <el-tooltip
                            v-else-if="
                                scope.row.customerMainContactBOList &&
                                scope.row.customerMainContactBOList.length > 0
                            "
                            class="item"
                            effect="dark"
                            placement="bottom"
                        >
                            <div slot="content" class="way_box">
                                <p
                                    v-for="item in scope.row
                                        .customerMainContactBOList"
                                    :key="item.id"
                                >
                                    <span class="way_box_fs">
                                        <em>
                                            {{
                                                $tableDataHandle.contactWay(
                                                    item
                                                )
                                            }}</em
                                        >
                                        <em class="contactContent">{{
                                            item.contactContent
                                        }}</em>
                                        {{ item.contactName }}
                                    </span>
                                </p>
                            </div>
                            <div style="position: relative">
                                <span>
                                    <i
                                        :class="
                                            $tableDataHandle.contactWayColor(
                                                scope.row
                                                    .customerMainContactBOList[0]
                                            ).icon
                                        "
                                        :style="{
                                            color: $tableDataHandle.contactWayColor(
                                                scope.row
                                                    .customerMainContactBOList[0]
                                            ).color,
                                        }"
                                    ></i
                                    >{{
                                        scope.row.customerMainContactBOList[0]
                                            .contactContent
                                    }}
                                </span>
                            </div>
                        </el-tooltip>
                    </div>
                    <div v-else>- -</div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="编号" show-overflow-tooltip width="85">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.lineNo ? scope.row.lineNo : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="线索产品" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.productClue
                                ? $tableDataHandle.productCategory(
                                      scope.row.productClue
                                  )
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop=""
                label="线索类型"
                show-overflow-tooltip
                width="90"
            >
                <template slot-scope="scope"
                    ><div>
                        {{ $tableDataHandle.clueType(scope.row.clueType) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="客户名称"
                show-overflow-tooltip
                prop="companyName"
                width="239"
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.companyName
                                ? scope.row.companyName
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="需求"
                show-overflow-tooltip
                prop="remark"
                width="173"
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.remark ? scope.row.remark : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="分配部门"
                prop="followDepartmentName"
                width="80"
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.followDepartmentName
                                ? scope.row.followDepartmentName
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="负责人"
                prop="followAdminName"
                show-overflow-tooltip
                width="55"
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.followAdminName
                                ? scope.row.followAdminName
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="状态" show-overflow-tooltip width="61">
                <template slot-scope="scope">
                    {{
                        $tableDataHandle.clueStatus(scope.row.status)
                    }}</template
                >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" fixed="right" width="120">
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        @click="onDistDep(scope.row)"
                        v-if="
                            btnP.distDep &&
                            searchData.param.sourceCategory == 1 &&
                            (scope.row.status == 12 ||
                                scope.row.status == 13 ||
                                scope.row.status == 4 ||
                                scope.row.status == 15)
                        "
                    >
                        分配部门
                    </el-button>
                    <el-button
                        style="color: #2370eb"
                        @click="onRevoke(scope.row)"
                        type="text"
                        v-if="
                            btnP.revoke &&
                            searchData.param.sourceCategory == 1 &&
                            scope.row.status == 14
                        "
                    >
                        撤回
                    </el-button>
                    <el-button
                        v-if="scope.row.status != 8"
                        style="color: #2370eb"
                        @click="onDealings(scope.row)"
                        type="text"
                    >
                        往来
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <!-- 线索撤回 -->
        <el-dialog
            :visible.sync="dialogRevoke"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog_add_customer"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    撤回线索
                </div>
            </template>
            <p class="dl_con">
                <i class="el-icon-warning-outline"></i>
                即将撤回选择的已分配线索，是否确认？
            </p>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" plain round size="mini"
                    >取 消</el-button
                >
                <el-button
                    style="background: #2370eb"
                    type="primary"
                    @click="subRevoke"
                    round
                    size="mini"
                    >确 认</el-button
                >
            </div>
        </el-dialog>
        <!-- 分配部门 -->
        <el-dialog
            :visible.sync="dialogDistribution"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog_add_customer"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    分配部门
                </div>
            </template>
            <div class="dialog_info">
                <DistDep ref="distdep" @close="handleClose" />
            </div>
        </el-dialog>
        <!-- 往来记录 -->
        <el-drawer
            :visible.sync="drawerDealings"
            :direction="direction"
            :before-close="handleClose"
            size="880px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    往来记录
                </div>
            </template>
            <Dealings
                v-if="drawerDealings"
                @dealingAdd="dealingAdd"
                @close="handleClose"
                @addExplain="addExplain"
                ref="dealings"
            />
        </el-drawer>
    </div>
</template>
<script>
import { clueList, clueUpDate, clueAddSynergy } from '@/api/clue/meClue';
import { getClue } from '@/api/clue/depClue.js';
import { addClueDealing } from '@/api/user/clue.js';
import { clueDealingList } from '@/api/clue/depClue';
import { intercourseAdd } from '@/api/clue/meClue';
import Dealings from '../../../database/dealings/dealings.vue';
export default {
    components: {
        Dealings,
    },
    data() {
        return {
            tableData: [{}],
            btnP: {},
            row: {},
            dialogRevoke: false,
            dialogDistribution: false,
            searchData: {},
            multipleSelection: [],
            drawerDealings: false,
            direction: 'rtl',
            isSelection: false,
        };
    },
    methods: {
        // 获取列表
        getData(btnP, data) {
            this.btnP = btnP;
            this.searchData = data;
            console.log(data);
            clueList(data).then((res) => {
                this.$emit('pageData', res.data.total);
                this.tableData = res.data.list;
            });
        },
        getSelection(type) {
            this.isSelection = type;
            console.log(type);
        },
        handleDisable(row) {
            if (
                (row.sourceCategory =
                    1 &&
                    (row.status == 12 ||
                        row.status == 13 ||
                        row.status == 4 ||
                        row.status == 15))
            ) {
                return true;
            } else {
                return false;
            }
        },
        handleSelectionChange(val) {
            var initalObj = Object.assign([], val);
            this.multipleSelection = initalObj;
        },
        // 分配
        onDistDep(row) {
            this.dialogDistribution = true;
            setTimeout(() => {
                this.$refs.distdep.getData(row);
            });
        },
        // 撤回弹窗
        onRevoke(row) {
            this.row = row;
            this.dialogRevoke = true;
        },
        // 撤回
        subRevoke() {
            let data = {
                param: {
                    id: this.row.id,
                    status: 13,
                },
            };
            clueUpDate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');

                    this.handleClose();
                }
            });
        },
        // 关闭抽屉
        handleClose() {
            this.dialogRevoke = false;
            this.dialogDistribution = false;
            this.dialogClueAssist = false;
            this.drawerDealings = false;
            this.getData(this.btnP, this.searchData);
        },
        onDealings(row) {
            this.drawerDealings = true;
            let data = {
                param: {
                    id: row.id,
                },
            };
            getClue(data).then((res) => {
                if (res.code == 200) {
                    this.clueDealingList(res.data);
                }
            });
        },
        // 往来列表
        clueDealingList(row) {
            this.row = row;
            let data = {
                param: {
                    lineId: row.id,
                },
            };
            clueDealingList(data).then((res) => {
                res.data.list.forEach((item) => {
                    item.productClue = row.productClue;
                });
                this.$refs.dealings.$data.historyData = res.data.list;
                this.$refs.dealings.$data.data = row;
            });
        },
        // 往来添加
        dealingAdd(data) {
            data.param.lineId = this.$refs.dealings.$data.data.id;
            addClueDealing(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.clueDealingList(this.$refs.dealings.$data.data);
                }
            });
        },
        // 往来添加说明
        addExplain(data) {
            intercourseAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    this.clueDealingList(this.row);
                }
            });
        },
        telSearch(row) {
            this.$emit('telSearch', row);
        },
        onDetails(row) {
            this.$router.push({
                path: '/cluedetails',
                query: {
                    id: row.id,
                },
            });
            sessionStorage.setItem('pageData', JSON.stringify(this.searchData));
            this.$formDetails.commit('detailsData', JSON.stringify(row));
            this.$choiceLabel.commit('getType', '1');
        },
        returnWay(row) {
            let index = row.lineContactBOList.findIndex((item) => {
                return item.number > 0;
            });
            if (index == -1) {
                return row.lineContactBOList[0];
            } else {
                return row.lineContactBOList[index];
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}

.icon-dianhua,
.icon-weixin,
.icon-QQ,
.icon-diannao,
.icon-youxiang,
.icon-youxiang1,
.icon-duanxinxiaoxixinxi,
.icon-qita,
.icon-yuyuebaifang,
.icon-qita1 {
    position: static;
    left: -10px !important;
}
.table-box {
    width: 100%;
    height: 100%;

    // /deep/ .el-table tr td {
    //     padding: 5px 0;
    // }
    .left_toast {
        position: absolute;
        top: 0;
        left: 10px;
        font-size: 12px;
        line-height: 1.2;
    }
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
.copy {
    height: 20px;
    background: #fff1d9;
    border-radius: 20px;
    line-height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    display: inline-block;
    padding: 0 8px 0 25px;
    color: #ff9c39;
    i {
        position: absolute;
        left: -10px;
        top: -10px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ff9c39;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        transform: scale(0.5);
    }
}
.copy2 {
    height: 20px;
    background: #fff1d9;
    border-radius: 20px;
    line-height: 20px;

    font-size: 12px;
    display: inline-block;
    padding: 0 8px 0 25px;
    color: #ff9c39;
    position: relative;
    i {
        position: absolute;
        left: -10px;
        top: -10px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ff9c39;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        transform: scale(0.5);
    }
}
.way_box_fs {
    line-height: 30px;
    em {
        display: inline-block;
        width: 30px;
        font-style: normal;
    }
    .contactContent {
        width: 120px !important;
        display: inline-block;
    }
}
</style>
<style>
.el-checkbox__input.is-disabled .el-checkbox__inner {
    display: none;
}
</style>
