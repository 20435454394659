<template>
    <!-- v-if="Boolean(depSearch)" -->
    <span style="margin: 0">
        <span class="title">{{ isAchievement ? '产品' : '线索产品' }}</span>
        <el-cascader
            size="small"
            ref="cascader"
            :disabled="isDisabled"
            v-model="productId"
            :options="productListData"
            :style="{ width: productWidth + 'px' }"
            :show-all-levels="false"
            :clearable="!isDisabled"
            @change="changeProductId"
            :props="{
                expandTrigger: 'hover',
                emitPath: false,
                value: 'id',
                label: 'productName',
                children: 'children',
            }"
        >
        </el-cascader>
    </span>
</template>

<script>
import { productList } from '@/api/product/product.js';
export default {
    name: '',
    props: ['productWidth', 'isAchievement'],
    data() {
        return {
            productId: '',
            productListData: [],
            btnP: {},
            isDisabled: '',
        };
    },
    components: {},
    created() {},
    methods: {
        getData(isDisabled = false) {
            this.isDisabled = isDisabled;
            let data = {
                param: {
                    status: 1,
                },
                pageSize: 0,
                pageNum: 0,
            };
            productList(data).then((res) => {
                this.productListData = this.$Tree.treeDataTranslate(
                    res.data.list,
                    'id',
                    'parentId',
                    false
                );
            });
        },
        changeProductId(val) {
            this.$emit('changeProductId', val);
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .elm-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.title {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin: 0 16px;
}
</style>
